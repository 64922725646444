"use strict";

var
activeNav = function(){
	var 
		_href = document.location.pathname,
		_target = $('.nav-main a[href="' + _href + '"]');

	_target.parents('li').addClass('active');
};

$(function() {
	activeNav();

	// if (window.PIE) {
	// 	$('#global-wrap, .no-boxshadow.gt7 #content .company a, #content h1').each(function() {
	// 		PIE.attach(this);
	// 	});
	// }


})